import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { SolicitudContext } from "context/solicitud/solicitudContext";
import { useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import DatosCotizacionProducto from "components/DatosCotizacionProducto";
import PaperServicios from "components/PaperServicios/PaperServicios";
import MotivosCotizacionSelect from "components/MotivosCotizacionSelect/MotivosCotizacionSelect";

import SaveIcon from '@mui/icons-material/Save';

export default function NuevaCotizacionFormacion() {
    const history = useHistory();
    const { stateSolicitud } = useContext(SolicitudContext);

    const navigateToSolicitud = useCallback(() => {
        history.push(`/solicitud/${stateSolicitud.idSolicitud}`);
    }, [stateSolicitud, history]);

    const save = (event) => {

    }

    return (
        <>
            <Box marginTop={"4vh"} sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }} alignItems={"flex-end"} >
                <Button
                    variant='outlined'
                    onClick={navigateToSolicitud}
                    startIcon={<ArrowBackIosNewIcon />} >Volver a cotizaciones</Button>
            </Box>
            <Paper className="my_paper" elevation={5} sx={{ position: "relative", padding: "2vw" }}>
                <Grid>
                    <Typography variant="h6">Nueva cotización</Typography>
                    <TextField
                        style={{ width: "30vw", marginTop: "2vh" }}
                        label="Nombre cotización"
                        type="text"
                        required />

                    <FormControl sx={{ marginTop: "2vh", marginLeft: "2%" }}>

                        <InputLabel id='label-tipo'>Tipo</InputLabel>
                        <Select
                            labelId='label-tipo-curso'
                            label="Tipo"
                            name="tipo"
                            style={{ width: "20vw" }}
                        >
                            <MenuItem value={"INCOMPANY"}>Incompany</MenuItem>
                            <MenuItem value={"ABIERTO"}>Abierto</MenuItem>
                        </Select>

                    </FormControl>
                </Grid>
                
                <Box sx={{marginTop: "2vh", width: "100%", display: "flex", justifyContent: "flex-end"}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={save}
                        startIcon={<SaveIcon />}
                    >Guardar</Button>
                </Box>

            </Paper>
        </>
    )
}