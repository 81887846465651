import { getDataAsync, postDataAsync } from "services/httpRequest";

export const getSecciones = async () => {
   const response = await getDataAsync('documentos/secciones');
   if (response.status === 200 || response.status === 201) { 
      return response.data;
   } else {
      throw response
   }
 }

export const descargarDocumento = async (data) => {
   const response = await postDataAsync("propuesta/"+ data.propuestaId +"/descargarDocumento", data.data);
   if (response.status === 200 || response.status === 201) { 
      return response.data;
   } else {
      throw response
   }
}

export const enviarDocumento = async (data) => {
   const response = await postDataAsync("propuesta/"+ data.propuestaId +"/enviarDocumento", data.data);
   if (response.status === 200 || response.status === 201) { 
      return response.data;
   } else {
      throw response
   }
}