import {  Box, Checkbox, Chip, FormControl, FormControlLabel, Grid, MenuItem, TextField, Typography } from "@mui/material";
import ModalPortal from "components/modalPortal";
import { useEffect, useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from "react-toastify";
import { createOrganizacion, getEstadoCliente, getLocalidades, getPaises, getProvincias, getRubros, searchOrganizacionByCuit } from "services/Organizacion/organizacionService";



export const ModalNuevaOrganizacion = ({ onClose, isOpened, onNewEmpresa }) => {
    
    const [cuit, setCuit] = useState("");
    const [cuitError, setCuitError] = useState({ hasError: false, text: "" });
    const [esCuitMultiple, setEsCuitMultiple] = useState(false);
    const [esExtranjero, setEsExtranjero] = useState(false);
    const [estadoCliente, setEstadoCliente] = useState(null);
    const [rubros, setRubros] = useState([]);
    const [paises, setPaises] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const [razonSocial, setRazonSocial] = useState("");
    const [razonSocialAFIP, setRazonSocialAFIP] = useState("");
    const [email, setEmail] = useState("");
    
    const [selectedRubro, setSelectedRubro] = useState(null);
    const [selectedPais, setSelectedPais] = useState({id: 12, label: "Argentina"});
    const [selectedProvincia, setSelectedProvincia] = useState(null);
    const [selectedLocalidad, setSelectedLocalidad] = useState(null);
    const [domicilio, setDomicilio] = useState("");
    const [codigoPostal, setCodigoPostal] = useState("");
    const [telefono, setTelefono] = useState("");

    useEffect(() => {
        obtenerRubros();
        obtenerPaises();
        obtenerProvincias();

    }, []);


    function clearInputs(){
        setCuit("");
        setCuitError({ hasError: false, text: "" });
        setEsCuitMultiple(false);
        setEsExtranjero(false);
        setEstadoCliente(null);
        setRazonSocial("");
        setRazonSocialAFIP("");
        setEmail("");
        setSelectedRubro(null);
        setSelectedPais({id: 12, label: "Argentina"});
        setSelectedProvincia(null);
        setSelectedLocalidad(null);
        setDomicilio("");
        setCodigoPostal("");
        setTelefono("");
    }

    function validarSiExisteCuit(cuit) {
        searchOrganizacionByCuit(cuit)
        .then(organizacion => {
            if(organizacion && organizacion.razonSocial){
                setCuitError({ hasError: true, text: "El cuit esta relacionado a " + organizacion.razonSocial })
            }else{
                setCuitError({ hasError: false, text: ""})
            }
        })
        .catch(error => {
            setCuitError({ hasError: false, text: ""})
        });
    }

    async function validarEstadoCliente(cuit){
        try {
            const estado = await getEstadoCliente(cuit);
            setEstadoCliente(estado);
        } catch (error) {
            setEstadoCliente(null);
        }
    }

    function obtenerRubros(){
        getRubros()
        .then(listaRubros => {
            setRubros(listaRubros);
        })
        .catch(error => {
            console.error("Error al obtener rubros", error);
            setRubros([]);
        });
    }
    
    function obtenerPaises(){

        getPaises()
        .then(listaPaises => {
            setPaises(listaPaises);
        })
        .catch(error => {
            console.error("Error al obtener paises", error);
            setPaises([]);
        });
    }

    function obtenerProvincias(){

        getProvincias()
        .then(listaProvincias => {
            setProvincias(listaProvincias);
        })
        .catch(error => {
            console.error("Error al obtener provincias", error);
            setProvincias([]);
        });
    }

    function obtenerLocalidades(provinciaId){

        getLocalidades(provinciaId)
        .then(listaLocalidades => {
            
            const localidadesAPI = [{id: 0, nombre: "Otra"}];

            setLocalidades(localidadesAPI.concat(listaLocalidades));
        })
        .catch(error => {
            console.error("Error al obtener localidades", error);
            setLocalidades([]);
        });
    }

    function onSave(e){
        e.preventDefault();
        guardarOrganizacion();
    }

    async function guardarOrganizacion(){

        

        const data = {
            identificador: cuit,
            username: "nico",
            razonSocialAFIP: razonSocialAFIP,
            email: email,
            idRubro: selectedRubro.id,
            esCuitMultiple: esCuitMultiple,
            esExtranjero: esExtranjero,
            direccion: {
                esDireccionFiscal: true,
                domicilio: domicilio,
                codigoPostal: codigoPostal,
                telefono: telefono,
                tipo: {
                    id: 0
                },
                version: 0,
                pais: selectedPais,
                provincia: selectedProvincia,
                localidad: selectedLocalidad,
            }
        }

        if(razonSocial.trim() !== ""){
            data.razonSocial = razonSocial;
        }else{
            data.razonSocial = razonSocialAFIP;
        }

        try {

            const nuevaEmpresa = await createOrganizacion(data);
            onNewEmpresa(nuevaEmpresa);
        } catch (error) {
            toast.error("Error al guardar la organización")
        }
        clearInputs();
        onClose();
    }

    function handleCuitChange(e) {
        setCuit(e.target.value);
        validarSiExisteCuit(e.target.value);
        validarEstadoCliente(e.target.value);
    }

    function handleRubroChange(e, value) {
        setSelectedRubro(value); 
    }

    function handlePaisChange(e, value) {
        setSelectedPais(value); 
    }

    function handleProvinciaChange(e, value) {
        if(value){
            obtenerLocalidades(value.id);
            setSelectedProvincia(value); 
        }else{
            setSelectedProvincia(null); 
            setLocalidades([]);
            setSelectedLocalidad(null);
        }
    }

    function handleLocalidadChange(e, value) {
        setSelectedLocalidad(value); 
    }

    const rubrosOrdenadosPorNombre = rubros.sort((a, b) => a.nombre.localeCompare(b.nombre));
    const nombreIdentificador = esExtranjero ? "IDENTIFICADOR" :  "CUIT/CUIL";

    const esArgentina = selectedPais && selectedPais.id === 12;

    return (
        <ModalPortal
            onCancel={onClose}
            onSave={onSave}
            title=""
            isOpened={isOpened}
        >
            <div style={{ maxWidth: "900px", display: "flex" }}>

                <Grid container spacing={2}>
                    <Grid display={"flex"} direction={"row"} justifyContent={"space-between"} item xs={12} sm={12}>
                        <Box display={"flex"} flexDirection={"row"}>
                            <Typography variant="h6" >Nueva organización</Typography>
                            {estadoCliente &&<Chip label={estadoCliente.descripcion} sx={{marginLeft: "10px", backgroundColor: estadoCliente.color}} />}
                        </Box>
                        <FormControl>
                            <Box sx={{marginLeft:"10px"}} display={"flex"} flexDirection={"row"} alignItems={"center"} >
                            <FormControlLabel control={<Checkbox checked={esExtranjero} onChange={(_, check) => setEsExtranjero(check)} />} label="Es extranjero" />
                            </Box>
                        </FormControl>
                    </Grid>
                    <Grid display={"flex"} alignItems={"center"} direction={"row"} item xs={12} sm={6}>
                        <FormControl>
                            <TextField
                                fullWidth
                                required
                                value={cuit}
                                onChange={handleCuitChange}
                                label={nombreIdentificador}
                                error={cuitError.hasError && !esCuitMultiple}
                                helperText={cuitError.hasError && !esCuitMultiple ? cuitError.text : ""}
                            />
                        </FormControl>
                        <FormControl>
                            <Box sx={{marginLeft:"10px"}} display={"flex"} flexDirection={"row"} alignItems={"center"} >
                            <FormControlLabel control={<Checkbox checked={esCuitMultiple} onChange={(_, check) => setEsCuitMultiple(check)} />} label="Es multiple" />
                            </Box>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                            <TextField
                                fullWidth
                                onChange={(e) => setRazonSocial(e.target.value.toUpperCase())}
                                value={razonSocial}
                                label="Nombre o Razón Social de fantasía (opcional)"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                            <TextField
                                fullWidth
                                required
                                onChange={(e) => setRazonSocialAFIP(e.target.value.toUpperCase())}
                                value={razonSocialAFIP}
                                label="Nombre o Razón Social(AFIP)"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                            <TextField
                                fullWidth
                                required
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                label="Dirección e-mail"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <FormControl fullWidth >
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={selectedRubro}
                        onChange={handleRubroChange}
                        options={rubrosOrdenadosPorNombre.map(rubro => ({ id: rubro.id, label: rubro.nombre }))}
                        renderInput={(params) => <TextField {...params} 
                        label="Rubro" />}
                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6" >Dirección Fiscal</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={selectedPais}
                        onChange={handlePaisChange}
                        options={paises.map(pais => ({ id: pais.id, label: pais.descripcion }))}
                        renderInput={(params) => <TextField {...params} 
                        label="País" />}
                        />
                        </FormControl>
                    </Grid>
                    {esArgentina && <Grid item xs={12} sm={6}>
                    <FormControl fullWidth >
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={selectedProvincia}
                        onChange={handleProvinciaChange}
                        options={provincias.map(provincia => ({ id: provincia.id, label: provincia.descripcion }))}
                        renderInput={(params) => <TextField {...params} 
                        label="Provincia" />}
                        />
                    </FormControl>
                        
                    </Grid>}
                    {esArgentina && <Grid item xs={12} sm={6}>
                    <FormControl fullWidth >
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={selectedLocalidad}
                        onChange={handleLocalidadChange}
                        options={localidades.map(localidad => ({ id: localidad.id, label: localidad.nombre }))}
                        renderInput={(params) => <TextField {...params} 
                        label="Localidad" />}
                        />
                    </FormControl>
                    </Grid>}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                            <TextField
                                fullWidth
                                required
                                onChange={(e) => setDomicilio(e.target.value)}
                                value={domicilio}
                                label="Domicilio"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                            <TextField
                                fullWidth
                                required
                                onChange={(e) => setCodigoPostal(e.target.value)}
                                value={codigoPostal}
                                label="Código Postal"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                            <TextField
                                fullWidth
                                required
                                onChange={(e) => setTelefono(e.target.value)}
                                value={telefono}
                                label="Teléfono"
                            />
                        </FormControl>
                    </Grid>
                </Grid>

            </div>
        </ModalPortal>
    );
}