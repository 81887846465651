import { addDatosIntegracion, addDatosNormaSitio, deleteDatosNormaSitio, deleteGrupoSitio, deleteSitio, postCotizaciones, postGrupoSitio, postSitio, updateCotizacionesSitio, updateDatosCotizacion, updateDatosGrupoSitios, updateDatosSitio, updateEstadoCotizacion, updateObservaciones, _actualizarSitioConcreto, _agregarSitioConcreto, _duplicarCotizacion, _duplicarSitio, _eliminarSitioConcreto } from "services/Cotizacion";
import { _getProductos, _getProductosProducto, _getPropuestaById, _ordenarConceptos, _postConcepto, _postDuplicarPropuesta, _postPropuesta, _putConcepto, _putPropuesta, _putPropuestaEstado } from "services/Propuesta";
import { parseNormas } from "utlis/normasParser";
import { putCotizacionAlimentos } from "services/CotizacionAlimentos";
import { SET_SAVING, SET_OBSERVACIONES, SET_JSON, RESET_NORMAS, SET_NORMAS_MOTIVO, SET_NUEVA_NORMA, DELETE_NORMA, SET_SAVED, SET_INPUT_INTEGRACION, RESET_INTEGRACION, RESET_COTIZACION, RESET_ESTADO_COTIZACION, SET_INFO_COTIZACION, SET_NUEVO_TURNO, SET_TURNOS, DELETE_TURNO, SET_NUEVO_NACE, SET_NUEVO_SUBNACE, DELETE_NACE, SET_ALL_ACCORDION_CLOSE, SET_ESTADO_COTIZACION, SET_LISTA_RANGO_PERSONAL, ADD_INPUT_IAF_NACE, DELETE_CODIGO_IAF_NACE, SET_INPUT_IAF_NACE, RESET_IAF_NACE, SET_ESTADO_CARGA, SET_TAB, SET_PROPUESTA, SET_PRODUCTOS, SET_MULTISITIOS, SET_SECCIONESDOCUMENTO, SET_SECCIONESEDIT, UPDATE_TIPO_CALCULO, SET_DOCUMENTO_TEMP, AGREGAR_SERVICIO, ACTUALIZAR_SERVICIO, BORRAR_SERVICIO, GUARDAR_COTIZACION_PRODUCTO, SET_NOMBRE_COTIZACION, SET_NACE, ACTUALIZAR_SERVICIOS, RESET_TURNOS } from "context/cotizacion/typesCotizacion/TypesCotizacion";
import { setError } from "redux/slices/globalSlice";
import { store } from "redux/store";


//loading actions
const estadoCargaLoading = () => ({
    type: SET_ESTADO_CARGA,
    payload: {
        guardando: true,
        guardado: false,
        error: false
    }
});


const estadoCargaSuccess = () => ({
    type: SET_ESTADO_CARGA,
    payload: {
        guardando: false,
        guardado: true,
        error: false
    }
})

const estadoCargaError = () => ({
    type: SET_ESTADO_CARGA,
    payload: {
        guardando: false,
        guardado: false,
        error: true
    }
})
//loading actions end


// common actions
export const setEstadoCarga = (estado) => ({
    type: SET_ESTADO_CARGA,
    payload: estado
})


export const setSaving = (saving) => ({
    type: SET_SAVING,
    payload: saving
});

export const setSaved = (saved) => ({
    type: SET_SAVED,
    payload: saved
});

export const setJson = (json) => ({
    type: SET_JSON,
    payload: json
});

export const setNombreCotizacion = (nombre) => ({
    type: SET_NOMBRE_COTIZACION,
    payload: { nombre }
});

export const resetNormas = () => ({
    type: RESET_NORMAS,
    payload: null
})

export const setNormaMotivo = (normaMotivo) => ({
    type: SET_NORMAS_MOTIVO,
    payload: normaMotivo
})

export const setNuevaNorma = (nuevaNorma) => ({
    type: SET_NUEVA_NORMA,
    payload: nuevaNorma
})

export const deleteNorma = (id) => ({
    type: DELETE_NORMA,
    payload: id
})

export const setGuardado = () => ({
    type: SET_SAVED,
    payload: null
})

export const setInputIntegracion = (integracion) => ({
    type: SET_INPUT_INTEGRACION,
    payload: integracion
})

export const resetIntegracion = () => ({
    type: RESET_INTEGRACION,
    payload: null
})

export const resetCotizacion = () => ({
    type: RESET_COTIZACION,
    payload: null
})

export const resetTurnos = () => ({
    type: RESET_TURNOS,
    payload: null
})

export const resetIAFNACE = () => ({
    type: RESET_IAF_NACE,
    payload: null
})

export const resetEstadoCotizacion = () => ({
    type: RESET_ESTADO_COTIZACION,
    payload: null
});

export const setDatosCotizacion = (info) => ({
    type: SET_INFO_COTIZACION,
    payload: info
})

export const setNuevoTurno = () => ({
    type: SET_NUEVO_TURNO,
    payload: null
})

export const setTurnos = (turnos) => ({
    type: SET_TURNOS,
    payload: turnos
})

export const deleteTurno = (turno) => ({
    type: DELETE_TURNO, 
    payload: turno
})

export const setNuevoNace = () => ({
    type: SET_NUEVO_NACE,
    payload: null
})

export const setNACEs = (naces) => ({
    type: SET_NACE,
    payload: naces
})

export const setNuevoSubnace = (subnace) => ({
    type: SET_NUEVO_SUBNACE,
    payload: subnace
})

export const deleteNace = (nace) => ({
    type: DELETE_NACE,
    payload: nace
})

export const setEstadoCotizacion = (estado) => ({
    type: SET_ESTADO_COTIZACION,
    payload: estado
})

export const setAllAccordionClose = (state) => ({
    type: SET_ALL_ACCORDION_CLOSE,
    payload: state
})

export const setObservaciones = (texto) => ({
    type: SET_OBSERVACIONES,
    payload: texto
})

export const setInputIafNace = (inputs) => ({
    type: SET_INPUT_IAF_NACE,
    payload: inputs
})

export const nuevoInputIAFNace = () => ({
    type: ADD_INPUT_IAF_NACE,
    payload: null
})

export const deleteCodigoIafNace = (indice) => ({
    type: DELETE_CODIGO_IAF_NACE,
    payload: indice
})

export const setTab = (tab) => {
    return ({
        type: SET_TAB,
        payload: tab
    })
}

export const setMultisitios = (multi) => {
    return ({
        type: SET_MULTISITIOS,
        payload: multi
    })
}
export const updateTipoCalculo = (tipo) => {
    return ({
        type: UPDATE_TIPO_CALCULO,
        payload: tipo
    })
}

//SITIOS
export const setListaRangoPersonal = (lista) => ({
    type: SET_LISTA_RANGO_PERSONAL,
    payload: lista
})


export const nuevoServicio = () => ({
    type: AGREGAR_SERVICIO,
    payload: null
})

export const actualizarServicios = (servicios) => ({
    type: ACTUALIZAR_SERVICIOS,
    payload: { servicios }
})

export const actualizarServicio = (index, servicio) => ({
    type: ACTUALIZAR_SERVICIO,
    payload: { index, servicio }
})

export const borrarServicio = (index) => ({
    type: BORRAR_SERVICIO,
    payload: { index }
})

// async actions
//DUPLICAR COTIZACION
export const duplicarCotizacion = (dispatch, id, handleOpenCotizacion) => {

    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))

    _duplicarCotizacion(id)
        .then(response => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
            let estadoAux = { ...response.estado }
            estadoAux.motivoRechazo = response.motivoRechazo
            estadoAux.posiblesTransiciones = estadoAux.posiblesTransiciones.concat(estadoAux.estado)
            dispatch(setEstadoCotizacion(estadoAux))
            const normasMotivos = parseNormas(response.normas)

            dispatch(setNormaMotivo(normasMotivos))

            const info = {
                nombre: response.nombre,
                seguimientos: response.cantidadMantenimientos,
                codigo: response.codigo,
                revision: 'D',
            }

            dispatch(setDatosCotizacion(info))

            if (response.infoIntegracion) {
                dispatch(setInputIntegracion(response.infoIntegracion))
            }
            dispatch(setJson(response));
            handleOpenCotizacion(response.id)
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("params: ")
            console.error("ERROR DUPLICANDO COTIZACION", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

//CALCULO GENERAL
export const updateCotizacion = (dispatch, cotizacion) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    updateDatosCotizacion(cotizacion)
        .then(response => {
            const normasMotivos = parseNormas(response.normas)
            dispatch(setNormaMotivo(normasMotivos))
            dispatch(setJson(response))
            dispatch(updateTipoCalculo(response.usaMultisitios ? "MULTISITIOS" : "GENERAL"))

            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("params: ", cotizacion)
            console.error("ERROR ACTUALIZANDO COTIZACION", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const updateNorma = (dispatch, idCotizacion, idGrupo, idSitio, norma) => {

    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    updateCotizacionesSitio(idCotizacion, idGrupo, idSitio, norma)
        .then(response => {
            dispatch(setJson(response));
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))

        })
        .catch(error => {

            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))

            console.error("ERROR ACTUALIZANDO NORMA", error);
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))

        });
}

//MUESTREO DE SITIOS
export const AddGrupoSitios = (dispatch, id, grupoSitios) => {

    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))

    postGrupoSitio(id, grupoSitios)
        .then(response => {
            dispatch(setJson(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR AGREGANDO GRUPO DE SITIOS", error);
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const AddSitios = (dispatch, data) => {

    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))

    postSitio(data.idCotizacion, data.idGrupoSitios, data.sitio)
        .then(response => {
            dispatch(setJson(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR AGREGANDO SITIO", error);
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const updateGrupoSitios = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    updateDatosGrupoSitios(data.idCotizacion, data.grupo)
        .then(response => {
            dispatch(setJson(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR ACTUALIZANDO GRUPO DE SITIOS", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const updateSitios = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    updateDatosSitio(data.idCotizacion, data.idGrupo, data.sitio)
        .then(response => {
            dispatch(setJson(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR ACTUALIZANDO SITIO", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const deleteNormaSitio = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    deleteDatosNormaSitio(data.idCotizacion, data.idGrupo, data.idSitio, data.idNorma)
        .then(response => {
            dispatch(setJson(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR ELIMINANDO NORMA SITIO", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const agregarNormaSitio = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    addDatosNormaSitio(data.idCotizacion, data.idGrupo, data.idSitio, data.idNorma)
        .then(response => {
            dispatch(setJson(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR AGREGANDO NORMA SITIO", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const eliminarSitio = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    deleteSitio(data.idCotizacion, data.idGrupo, data.idSitio)
        .then(response => {
            dispatch(setJson(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR ELIMINANDO SITIO", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const eliminarGrupo = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    deleteGrupoSitio(data.idCotizacion, data.idGrupo)
        .then(response => {
            dispatch(setJson(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR ELIMINANDO GRUPO", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const agregarIntegracion = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    addDatosIntegracion(data.idCotizacion, data.idGrupo, data.idSitio)
        .then(response => {
            dispatch(setJson(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR AGREGANDO INTEGRACION", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const duplicarSitio = (dispatch, data) => {
    dispatch(estadoCargaLoading())
    _duplicarSitio(data.idCotizacion, data.idGrupo, data.idSitio)
        .then(response => {
            dispatch(estadoCargaSuccess())
            dispatch(setJson(response))
        })
        .catch(error => {
            dispatch(estadoCargaError())
            console.error("ERROR DUPLICANDO SITIO", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}
//ESTADO COTIZACION
export const actualizarEstadoCotizacion = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    updateEstadoCotizacion(data)
        .then((response) => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
            let estadoAux = { ...response.estado }
            estadoAux.posiblesTransiciones = estadoAux.posiblesTransiciones.concat(estadoAux.estado)
            dispatch(setEstadoCotizacion(estadoAux))
        })
        .catch((error) => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR ACTUALIZANDO ESTADO COTIZACION", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

//OBSERVACIONES
export const actualizarObservaciones = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    updateObservaciones(data.id, data.observaciones)
        .then(response => {
            dispatch(setObservaciones(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR ACTUALIZANDO OBSERVACION", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

//SITIOS CONCRETOS
export const agregarSitioConcreto = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    _agregarSitioConcreto(data.idCotizacion, data.idGrupo, data.idSitio, data.nombre)
        .then(response => {
            dispatch(setJson(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR ACTUALIZANDO OBSERVACION", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const actualizarSitioConcreto = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    _actualizarSitioConcreto(data.idCotizacion, data.idGrupo, data.idSitio, data)
        .then(response => {
            dispatch(setJson(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR ACTUALIZANDO OBSERVACION", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const eliminarSitioConcreto = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    _eliminarSitioConcreto(data.idCotizacion, data.idGrupo, data.idSitio, data.idSitioConcreto)
        .then(response => {
            dispatch(setJson(response))
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR ACTUALIZANDO OBSERVACION", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}


//PROPUESTAS

export const setPropuesta = (propuesta) => {
    return {
        type: SET_PROPUESTA,
        payload: propuesta
    };
}

export const setProductos = (productos) => {
    return {
        type: SET_PRODUCTOS,
        payload: productos
    };
}

export const obtenerPropuestaByIdSilent = (dispatch, data) => {
    _getPropuestaById(data.id)
        .then(response => {
            dispatch(setPropuesta(response))
            data?.finalCotizacion?.current?.scrollIntoView({ behavior: "smooth" })
        })
        .catch(error => {
            console.error("ERROR OBTENIENDO PROPUESTA", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error?.data?.details ? error?.data?.details : "sin detalles" }))
        })
}

export const obtenerPropuestaById = (dispatch, data) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    _getPropuestaById(data.id)
        .then(response => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
            dispatch(setPropuesta(response))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR OBTENIENDO PROPUESTA", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error?.data?.details ? error?.data?.details : "sin detalles" }))
        })
}

export const generarPropuesta = (dispatch, data, globalDispatch) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    _postPropuesta(data)
        .then(response => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
            let propuestasAux = data.json.propuestas.concat({ id: response.id, version: response.version })
            dispatch(setJson({ ...data.json, propuestas: propuestasAux }))
            dispatch(setPropuesta(response))

        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR CREANDO PROPUESTA", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const duplicarPropuesta = (dispatch, data, globalDispatch) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))

    _postDuplicarPropuesta(data)
        .then(response => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
            let propuestasAux = data.json.propuestas.concat({ id: response.id, version: response.version })
            dispatch(setJson({ ...data.json, propuestas: propuestasAux }))
            dispatch(setPropuesta(response))

        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR DUPLICANDO PROPUESTA", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const postConcepto = (dispatch, data, globalDispatch) => {
    dispatch(estadoCargaLoading())
    _postConcepto(data)
        .then(response => {
            dispatch(estadoCargaSuccess())
        })
        .catch(error => {
            dispatch(estadoCargaError())
            console.error("ERROR CREANDO CONCEPTO", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))

        })

}

export const updateConcepto = (dispatch, data, globalDispatch) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    _putConcepto(data)
        .then(response => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
            let propuestaAux = { ...data.propuesta }
            let conceptoIndex = propuestaAux.conceptos.findIndex(c => c.id === response.id)
            propuestaAux.conceptos[conceptoIndex] = response
            dispatch(setPropuesta(propuestaAux))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR ACTUALIZANDO CONCEPTO", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const ordenarConceptos = (dispatch, data, globalDispatch) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    _ordenarConceptos(data.idPropuesta, data.conceptosOrdenados)
        .then(response => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))

        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("ERROR ACTUALIZANDO ORDEN CONCEPTOS", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const getProductos = (dispatch) => {
    _getProductos()
        .then(productos => {
            dispatch(setProductos(productos))
        })
        .catch(error => {
            console.error("ERROR OBTENIENDO PRODUCTOS", error)
        });
}

export const getProductosProducto = (dispatch, idCot) => {
    _getProductosProducto(idCot)
        .then(productos => {
            dispatch(setProductos(productos))
        })
        .catch(error => {
            console.error("ERROR OBTENIENDO PRODUCTOS", error)
        });
}

export const updatePropuestaEstado = (dispatch, data, globalDispatch) => {
    dispatch(estadoCargaLoading())
    _putPropuestaEstado(data)
        .then(response => {
            dispatch(setPropuesta(response))
            dispatch(estadoCargaSuccess())
        })
        .catch(error => {
            dispatch(estadoCargaError())
            console.error("ERROR ACTUALIZANDO ESTADO PROPUESTA", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const updatePropuestaTipoCalculo = (dispatch, idPropuesta, tipoCalculo, globalDispatch) => {
    dispatch(estadoCargaLoading())
    _putPropuesta(idPropuesta, { tipoCalculo })
        .then(response => {
            dispatch(setPropuesta(response))
            dispatch(estadoCargaSuccess())
        })
        .catch(error => {
            dispatch(estadoCargaError())
            console.error("ERROR ACTUALIZANDO TIPO CALCULO PROPUESTA", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const updateMuestraTotales = (dispatch, idPropuesta, mostrarTotales, globalDispatch) => {
    dispatch(estadoCargaLoading())
    _putPropuesta(idPropuesta, { mostrarTotales })
        .then(response => {
            dispatch(setPropuesta(response))
            dispatch(estadoCargaSuccess())
        })
        .catch(error => {
            dispatch(estadoCargaError())
            console.error("ERROR ACTUALIZANDO MOSTRAR TOTALES PROPUESTA", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const updatePresupuestoCerrado = (dispatch, idPropuesta, presupuestoCerrado, globalDispatch) => {
    dispatch(estadoCargaLoading())
    _putPropuesta(idPropuesta, { presupuestoCerrado })
        .then(response => {
            dispatch(setPropuesta(response))
            dispatch(estadoCargaSuccess())
        })
        .catch(error => {
            dispatch(estadoCargaError())
            console.error("ERROR ACTUALIZANDO PRESUPUESTO CERRADO PROPUESTA", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}

export const updatePropuestaAuditoriaDiagnostico = (dispatch, idPropuesta, usaAuditoriaDiagnostico, globalDispatch) => {
    dispatch(estadoCargaLoading())
    _putPropuesta(idPropuesta, { usaAuditoriaDiagnostico })
        .then(response => {
            dispatch(setPropuesta(response))
            dispatch(estadoCargaSuccess())
        })
        .catch(error => {
            dispatch(estadoCargaError())
            console.error("ERROR ACTUALIZANDO AUDITORIA DIAGNOSTICO PROPUESTA", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}



export const guardarNuevaCotizacionProducto = async (dispatch, globalDispatch, cotizacion) => {
    dispatch(estadoCargaLoading())
    try {
        const response = await postCotizaciones({
            idSolicitud: cotizacion.idSolicitud,
            tipo: "PRODUCTO",
            nombre: cotizacion.nombre,
            servicios: cotizacion.servicios
        });

        let estadoAux = { ...response.estado }
        estadoAux.posiblesTransiciones = estadoAux.posiblesTransiciones.concat(estadoAux.estado)
        dispatch(setEstadoCotizacion(estadoAux))

        const info = {
            nombre: response.nombre,
            seguimientos: response.cantidadMantenimientos,
            codigo: response.codigo,
        }
        dispatch(setDatosCotizacion(info))
        dispatch(setJson(response))
        dispatch(estadoCargaSuccess())
        return response;
    } catch (error) {
        dispatch(estadoCargaError())
        console.error("ERROR CREANDO COTIZACION TIPO PRODUCTO", error)
        store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
    }
}

export const actualizarCotizacionProducto = async (dispatch, globalDispatch, cotizacion) => {
    dispatch(estadoCargaLoading())
    try {
        const response = await updateDatosCotizacion({
            idSolicitud: cotizacion.idSolicitud,
            tipo: "PRODUCTO",
            nombre: cotizacion.nombre,
            id: cotizacion.id,
            servicios: cotizacion.servicios
        });

        let estadoAux = { ...response.estado }
        estadoAux.posiblesTransiciones = estadoAux.posiblesTransiciones.concat(estadoAux.estado)
        dispatch(setEstadoCotizacion(estadoAux))

        const info = {
            nombre: response.nombre,
            seguimientos: response.cantidadMantenimientos,
            codigo: response.codigo,
        }
        dispatch(setDatosCotizacion(info))
        dispatch(setJson(response))
        dispatch(estadoCargaSuccess())
        return response;
    } catch (error) {
        dispatch(estadoCargaError())
        console.error("ERROR ACTUALIZANDO COTIZACION TIPO PRODUCTO", error)
        store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
    }
}

//Documento
export const setSeccionesDocumento = (secciones) => ({
    type: SET_SECCIONESDOCUMENTO,
    payload: secciones
})

export const setSeccionesEdit = (secciones) => ({
    type: SET_SECCIONESEDIT,
    payload: secciones
})

export const setDocumentoTemp = (documento) => ({
    type: SET_DOCUMENTO_TEMP,
    payload: documento
})


//ALIMENTOS
//CALCULO GENERAL
export const updateCotizacionAlimentos = (dispatch, cotizacion, globalDispatch) => {
    dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
        error: false
    }))
    putCotizacionAlimentos(cotizacion)
        .then(response => {
            const normasMotivos = parseNormas(response.normas)
            dispatch(setNormaMotivo(normasMotivos))
            dispatch(setJson(response))
            // dispatch(updateTipoCalculo(response.usaMultisitios ? "MULTISITIOS" : "GENERAL"))

            dispatch(setEstadoCarga({
                guardando: false,
                guardado: true,
                error: false
            }))
        })
        .catch(error => {
            dispatch(setEstadoCarga({
                guardando: false,
                guardado: false,
                error: true
            }))
            console.error("params: ", cotizacion)
            console.error("ERROR ACTUALIZANDO COTIZACION", error)
            store.dispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
}