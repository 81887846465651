import React, { useContext } from 'react';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';


const SelectProductos = ({onChange}) => {
   
   const { state } = useContext(CotizacionContext)

   const onSelectProducto = ( _ , productoAutoComplete) => {
      const producto = state.productos.find(p => p.id ===  productoAutoComplete.id);
      onChange(producto);
   }

   return (
      <Autocomplete
      disablePortal
      onChange={onSelectProducto}
      id="combo-box-demo"
      options={state.productos.map(p => ({id: p.id, label: p.nombre}))}
      renderInput={(params) => <TextField {...params} label="Concepto" />}
      />
   );
};

export default SelectProductos;
