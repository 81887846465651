export const TIPO_GRUPO_SITIO = {
    GENERAL: "GENERAL",
    PROPIOS: "PROPIOS",
    TEMPORALES: "TEMPORALES",
    CASA_CENTRAL: "CASA_CENTRAL"
}

export const TIPO_ESTADO = {
    ENVIADA: "Enviada",
    BORRADOR: "Borrador",
    ACEPTADA: "Aceptada",
    DESCARTADA: "Descartada",
    RECHAZADA: "Rechazada"
}

export const PERMISOS = {
    LECTURA: "RDALector",
    ESCRITURA: "RDAEscritor",
}

export const COLUMNAS = {
    DIAS_IRAM: "diasIram",
    DIAS_ORGANIZACION: "diasOrganizacion",
    ARANCEL: "arancel",
    PERIODO: "periodo",
    BONIFICACION:"bonificacion",
    RECARGO: "recargo",
}

export const TIPO_CONCEPTO = {
    REALIZADA: "realizada",
    HABILITAR: "habilitar",
    A_COTIZAR: "aCotizar"
}

export const TIPO_AUDITORIA = {
    EXTRAORDINARIA:"AUDITORIA_EXTRAORDINARIA",
    AUDITORIA:"AUDITORIA",
    PRODUCTO:"PRODUCTO"
}

export const TIPO_SOLICITUD_ALIMENTOS = "ALIMENTOS";

export const TIPO_SOLICITUD_GESTION = "GESTION";

export const TIPO_SOLICITUD_PRODUCTO = "PRODUCTO";

export const TIPO_SOLICITUD_FORMACION = "FORMACION";

export const TIPO_SOLICITUD = [
    {
        key: TIPO_SOLICITUD_ALIMENTOS,
        value: "Alimentos"
    },
    {
        key: TIPO_SOLICITUD_GESTION,
        value: "Gestión"
    },
    // {
    //     key: TIPO_SOLICITUD_PRODUCTO,
    //     value: "Producto"
    // }
]

export const MAIL_BODY = 
`<p>Estimados, </p><p><br></p><p>Por medio del presente les hacemos llegar la <strong>Propuesta Técnico-Comercial</strong> para la Certificación / Recertificación / Seguimiento N°1 / Seguimiento N°2 / Seguimiento con Ampliación de Alcance de vuestro Sistema de Gestión de la Calidad / Ambiental / Salud en el Trabajo / Integrado, conforme los requisitos de la Norma <strong>IRAM-ISO 9001:2015, IRAM-ISO 14001:2015 e IRAM-ISO 45001:2018</strong>. </p><p><br></p><p>La misma se ha realizado en base a los datos aportados en el cuestionario y lo compartido oportunamente. Si están de acuerdo, para pasar a instancia de coordinación, vamos a necesitar que nos remitan:</p><p> </p><ul><li><strong><u>PTC firmada en conformidad</u></strong> </li><li>Solicitud de Registro (última página) </li><li>“Cláusula Anticorrupción” completa y firmada en la última hoja </li><li> Dentro de los corchetes indicar nombre + apellido + cargo del firmante y razón social </li><li>“Acuerdo de Certificación” completo en página 1 y firmado y completo en página 13 -Documento que acredite personería del firmante del Acuerdo de Certificación y Cláusula Anticorrupción </li><li>Alta de Cliente - completo en formato Excel Quedo a disposición por cualquier consulta. Saludos cordiales.</li></ul><p><br></p><p><br></p>`