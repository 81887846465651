import React, { useContext, useEffect, useState,  } from 'react'
import {Grid, Typography, Table,TableRow, TableContainer, TableHead, TableBody, TableCell, Button, Paper, Chip, Tooltip, Drawer, Tab, Skeleton} from '@mui/material'
import { SolicitudContext } from 'context/solicitud/solicitudContext'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import { resetCotizacion, resetIAFNACE, resetEstadoCotizacion, resetIntegracion, resetNormas, duplicarCotizacion, resetTurnos } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
 
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Restricted, { RESTRICT_METHOD } from 'components/Restricted'
import { PERMISOS, TIPO_ESTADO, TIPO_SOLICITUD_PRODUCTO } from 'constants/constants'
import SummarizeIcon from '@mui/icons-material/Summarize';
import { Box } from '@mui/system'
import ResumenSolicitud from 'components/ResumenSolicitud'
import { getCotizacionById } from 'services/Cotizacion'
import ResumenCotizacion from 'components/ResumenCotizacion'
import { useDispatch } from 'react-redux'

const PaperListaCotizacion = (props) => {

    const history = useHistory();
    const dispatchRedux = useDispatch()
    const {stateSolicitud} = useContext(SolicitudContext);
    const {state, dispatch} = useContext(CotizacionContext)
    const {listaCotizaciones} = stateSolicitud;
    const {estadoCarga} = state

    const listaCotizacionSort = listaCotizaciones.sort((a,b) => (a.fechaModificacion < b.fechaModificacion)?1:-1)

    const navigateToNuevaCotizacion = () => {
        history.push(`/solicitud/${stateSolicitud.idSolicitud}/nuevacotizacion`);
    }

    const handleAgregarCotizacion = () => {
        navigateToNuevaCotizacion();
       
    }

    const handleOpenCotizacion = (id) =>{
        history.push(`/solicitud/${stateSolicitud.idSolicitud}/cotizacion/${id}`)
    }

    const handleDuplicate = (id) =>{
        
        duplicarCotizacion(dispatch, id, handleOpenCotizacion)
    }

    const handleOpenCotizacionPropuesta = (idCotizacion, idPropuesta) => {
        history.push(`/solicitud/${stateSolicitud.idSolicitud}/cotizacion/${idCotizacion}/propuesta/${idPropuesta}`)
    }

    useEffect(() => {
        dispatch(resetCotizacion());
        dispatch(resetIntegracion());
        dispatch(resetIAFNACE());
        dispatch(resetEstadoCotizacion());
        dispatch(resetNormas());
        dispatch(resetTurnos())
    }, [])
    
    const disabledDuplicar = (item) => {
        if(item.normas===null){
           return true
        }else{
           if(estadoCarga.guardando){
                 return true
           }else{
              if(stateSolicitud.tipo === "ALIMENTOS"){
                 return true
              }else{
                 return false
              }
           }
        }
     }
  

    const getColorEstado = (estado) => {
        switch(estado) {
            case TIPO_ESTADO.ACEPTADA: {
                return 'success';
            }
            case TIPO_ESTADO.ENVIADA: {
               return 'warning';
            }
            case TIPO_ESTADO.DESCARTADA: {
                return 'error';
             }
             case TIPO_ESTADO.RECHAZADA: {
                return 'error';
             }
            default: {
                return 'secondary';
            }
        }
        
    }

    //LOADING
    const [loading, setLoading] = useState(true)
    const [cotizacionResumen, setResumen] = useState(null)

     //Drawer
    const [openDrawer, setOpenDrawer] = useState(false)
    const closeDrawer = () => {
        setLoading(true)
        setOpenDrawer(false)
    }

    const handleResumen = (id) => {
        getCotizacionById(id)
        .then(response => {
            setResumen(response)
            setLoading(false)
        })
        .catch(error => {
            console.error("error", error)
        })
        setOpenDrawer(true)
    }
    
    return (
    <Paper className="my_paper" elevation={5}>
        <Grid container>
        <Grid item xs={8}>
            <Typography>Cotizaciones</Typography>
        </Grid>
        <Grid display={"flex"} justifyContent={"flex-end"} item xs={4}>
            <Restricted to={PERMISOS.LECTURA}>
                <Button color="primary" variant="contained" onClick={handleAgregarCotizacion}>+ NUEVA COTIZACIÓN</Button>
            </Restricted>
        </Grid>
        <Grid item xs={12}>
            <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Codigo</TableCell>
                        <TableCell>Elaborado</TableCell>
                        <TableCell>Nombre</TableCell>
                        {stateSolicitud && stateSolicitud.tipo !== TIPO_SOLICITUD_PRODUCTO && <TableCell>Normas</TableCell>}
                        <TableCell>Estado Propuestas</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        listaCotizaciones &&
                        listaCotizacionSort.map((item, index) =>
                        <TableRow key={"listcotizacion"+index}>
                            <TableCell>{item.codigo}</TableCell>
                            <TableCell>{item.creadaPor}</TableCell>
                            <TableCell>{item.nombre}</TableCell>
                            {
                                stateSolicitud && stateSolicitud.tipo !== TIPO_SOLICITUD_PRODUCTO && (item.normas?

                                <TableCell>{item.normas.map(n => n.norma.nombre).join(', ')}</TableCell>
                                :
                                <TableCell>Error</TableCell>)
                            }
                            
                            <TableCell>
                                <Box display="flex" sx={{width:"160px"}} flexDirection="row" flexWrap="wrap" gap={1}>
                                {
                                    item.propuestas &&
                                    <>
                                    {
                                        item.propuestas.map((propuesta, index) => 
                                        <Tooltip key={"chips"+index} title={propuesta.estado}>
                                        <Chip 
                                        size="small"
                                        label={"Version " + propuesta.version}
                                        onClick={() => handleOpenCotizacionPropuesta(item.id, propuesta.id)}
                                        color={getColorEstado(propuesta.estado)}
                                        />
                                        </Tooltip>
                                        )
                                    }
                                    </>
                                }
                                </Box>
                            </TableCell>
                            <TableCell>
                                
                                <Button 
                                    variant='contained' 
                                    color='primary' 
                                    disabled={item.normas === null && stateSolicitud.tipo !== TIPO_SOLICITUD_PRODUCTO}
                                    onClick={() =>handleOpenCotizacion(item.id)}
                                >ABRIR</Button>
                               
                                
                            </TableCell>
                            <TableCell>
                                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                                <Button
                                    variant='outlined'
                                    color="primary"
                                    startIcon={<ContentCopyIcon/>}
                                    disabled={disabledDuplicar(item)}
                                    onClick={() => handleDuplicate(item.id)}
                                    >DUPLICAR</Button>
                                </Restricted>
                            </TableCell>
                            <TableCell>
                                <Button
                                variant="outlined"
                                color="secondary"
                                startIcon={<SummarizeIcon/>}
                                onClick={() => handleResumen(item.id)}
                                >
                                    RESUMEN
                                </Button>
                            </TableCell>
                        </TableRow>
                        )
                    }
                </TableBody>
            </Table>
            </TableContainer>
        </Grid>
        </Grid>
        <Drawer
            anchor='left'
            open={openDrawer}
            onClose={closeDrawer}
        >
        {
            loading ?
            <LoaderResumenCotizacion /> 
            :
            <Box display="flex" flexDirection="column" style={{width:"50vw", paddingTop:"100px"}}>
                <ResumenSolicitud solicitud={stateSolicitud} cotizacion={cotizacionResumen}/>
                <ResumenCotizacion cotizacion={cotizacionResumen}/>
            </Box>
        }
    </Drawer>
    </Paper>
    )
}

function LoaderResumenCotizacion() {
    return (
        <>
            <Box sx={{ marginTop: "15vh" }} display="flex" flexDirection={"row"}>
                <Skeleton variant="rectangular" width={"10vw"} height={60} />
                <Skeleton sx={{marginLeft: "10px"}} variant="rectangular" width={"10vw"} height={60} />
                <Skeleton sx={{marginLeft: "10px"}} variant="rectangular" width={"10vw"} height={60} />
                <Skeleton sx={{marginLeft: "10px"}} variant="rectangular" width={"10vw"} height={60} />
            </Box>
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"50vw"} height={150} />
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"50vw"} height={150} />
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"50vw"} height={150} />
            <Skeleton sx={{ marginTop: "20px" }} variant="rectangular" width={"50vw"} height={150} />
        </>
    )
}


export default PaperListaCotizacion
