const { getDataAsync, putDataAsync, postDataAsync } = require("services/httpRequest");

async function searchOrganizaciones(query) {
    const response = await getDataAsync(`Organizaciones/search?query=${query}`);
    if (response.status === 200 || response.status === 201) {
        return response.data;
    } else {
        throw response;
    }
}

async function searchOrganizacionByCuit(cuit) {
    const response = await getDataAsync(`Organizaciones/searchByCuit?cuit=${cuit}`);
    if (response.status === 200 || response.status === 201) {
        return response.data;
    } else {
        throw response;
    }
}

async function getDetalleOrganizacion(idOrganizacion) {
    const response = await getDataAsync(`Organizaciones/${idOrganizacion}`);
    if (response.status === 200 || response.status === 201) {
        return response.data;
    } else {
        throw response;
    }
}

async function getPaises() {
    const response = await getDataAsync('Organizaciones/Paises');
    if (response.status === 200 || response.status === 201) {
        return response.data;
    } else {
        throw response;
    }
}

async function getProvincias(){
    const response = await getDataAsync('Organizaciones/Provincias');
    if (response.status === 200 || response.status === 201) {
        return response.data;
    } else {
        throw response;
    }
}

async function getLocalidades(idProvincia){
    const response = await getDataAsync(`Organizaciones/Provincias/${idProvincia}/Localidades`);
    if (response.status === 200 || response.status === 201) {
        return response.data;
    } else {
        throw response;
    }
}

async function getRubros(){
    const response = await getDataAsync('Organizaciones/Rubros');
    if (response.status === 200 || response.status === 201) {
        return response.data;
    } else {
        throw response;
    }
}

async function updateOrganizacion(organizacion){
    const response = await putDataAsync(`Organizaciones/${organizacion.id}`, organizacion);
    if (response.status === 200 || response.status === 201) {
        return response.data;
    } else {
        throw response;
    }
}

async function createOrganizacion(organizacion){
    const response = await postDataAsync(`Organizaciones/${organizacion.id}`, organizacion);
    if (response.status === 200 || response.status === 201) {
        return response.data;
    } else {
        throw response;
    }
}

async function getEstadoCliente(cuit){
    const response = await getDataAsync(`Organizaciones/estadoCliente?id=${cuit}`);
    if (response.status === 200 || response.status === 201) {
        return response.data;
    } else {
        throw response;
    }

}

export { 
    getPaises,
    getProvincias,
    getLocalidades,
    getRubros,
    getDetalleOrganizacion,
    updateOrganizacion,
    createOrganizacion,
    searchOrganizaciones,
    searchOrganizacionByCuit,
    getEstadoCliente
};